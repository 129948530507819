import {
  Document,
  Page,
  Text,
  View,
  PDFViewer,
  Image,
  pdf,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import Html from "react-pdf-html";

import Seite from "./Seite";
import { styles, stylesheet } from "./_helper/styles";

function BasicDocument() {
  const [title, setTitle] = React.useState(null);
  const [kurzinfo, setKurzinfo] = React.useState(null);
  const [beschreibung, setBeschreibung] = React.useState(null);
  const [tipp, setTipp] = React.useState(null);
  const [variation, setVariation] = React.useState(null);
  const [image, setImage] = React.useState(null);
  const [status, setStatus] = React.useState(false);
  const [zeit, setZeit] = React.useState(null);
  const [stufe, setStufe] = React.useState(null);
  const [technik, setTechnik] = React.useState(null);
  const [terrain, setTerrain] = React.useState(null);
  const [material, setMaterial] = React.useState(null);
  const [min, setMin] = React.useState(null);
  const [max, setMax] = React.useState(null);
  const [seiten, setSeiten] = React.useState(null);

  const queryParams = new URLSearchParams(window.location.search);

  const id = queryParams.get("id");
  const language = queryParams.get("language");

  React.useEffect(() => {
    if (id) {
      fetch(
        "https://www.swiss-cycling-ausbildung.ch/wp-json/spielesammlung/v1/print-spiele?id=" +
          id
      )
        .then((results) => results.json())
        .then((data) => {
          setTitle(data.title);
          setKurzinfo(data.kurzinfo);
          setBeschreibung(data.beschreibung);
          setTipp(data.tipp);
          setVariation(data.variation);

          let zeit = data.zeit[0]
            .replace(" Minuten", "'")
            .replace(" minutes", "'");
          setZeit(zeit);
          const stufe = data.stufe.map((itm) => <Text>{itm}</Text>);
          setStufe(stufe);
          const technik = data.technik.map((itm) => <Text>{itm}</Text>);
          setTechnik(technik);
          const terrain = data.terrain.map((itm) => <Text>{itm}</Text>);
          setTerrain(terrain);
          const material = data.material.map((itm) => <Text>{itm}</Text>);
          setMaterial(material);
          setMin(data.min);
          setMax(data.max);

          let bild = "/spiel-1";
          switch (data.bild) {
            case "/app/uploads/2022/05/spielesammlung-fangspiele.png":
              bild = "/fangspiele";
              break;
            case "/app/uploads/2022/05/spielesammlung-freiesspiel.png":
              bild = "/freiesspiel";
              break;
            case "/app/uploads/2022/05/spielesammlung-kampfspiele.png":
              bild = "/kampfspiele";
              break;
            case "/app/uploads/2022/05/spielesammlung-kennenlernen.png":
              bild = "/kennenlernen";
              break;
            case "/app/uploads/2022/05/spielesammlung-parcour.png":
              bild = "/parcour";
              break;
            case "/app/uploads/2022/05/spielesammlung-reaktionsspiele.png":
              bild = "/reaktionsspiele";
              break;
            case "/app/uploads/2022/05/spielesammlung-stafette.png":
              bild = "/stafette";
              break;
            case "/app/uploads/2022/05/spielesammlung-torschuss.png":
              bild = "/torschuss";
              break;
            case "/app/uploads/2022/05/spielesammlung-velokennenlernen.png":
              bild = "/velokennenlernen";
              break;
            case "/app/uploads/2022/05/spielesammlung-wurfspiele.png":
              bild = "/wurfspiele";
              break;
          }
          bild = language === "fr" ? bild + "-fr.png" : bild + ".png";
          const seite = (
            <Seite
              kurzinfo={data.kurzinfo}
              beschreibung={data.beschreibung}
              tipp={data.tipp}
              bild={bild}
              variation={data.variation}
              language={language}
              zeit={data.zeit[0]
                .replace(" Minuten", "'")
                .replace(" minutes", "'")}
              title={data.title}
              technik={technik.map((itm) => (
                <Text>{itm}</Text>
              ))}
              material={material.map((itm) => (
                <Text>{itm}</Text>
              ))}
              min={data.min}
              max={data.max}
              stufe={stufe.map((itm) => (
                <Text>{itm}</Text>
              ))}
              terrain={terrain.map((itm) => (
                <Text>{itm}</Text>
              ))}
            />
          );
          setSeiten(seite);
          setImage(bild);
          setStatus(true);
        });
    } else {
      fetch(
        "https://www.swiss-cycling-ausbildung.ch/wp-json/spielesammlung/v1/print-spiele"
      )
        .then((results) => results.json())
        .then((data) => {
          console.log(data);
          setStatus(true);

          const seiten = data.map((item) => {
            let bild = "/spiel-1";
            switch (item.bild) {
              case "/app/uploads/2022/05/spielesammlung-fangspiele.png":
                bild = "/fangspiele";
                break;
              case "/app/uploads/2022/05/spielesammlung-freiesspiel.png":
                bild = "/freiesspiel";
                break;
              case "/app/uploads/2022/05/spielesammlung-kampfspiele.png":
                bild = "/kampfspiele";
                break;
              case "/app/uploads/2022/05/spielesammlung-kennenlernen.png":
                bild = "/kennenlernen";
                break;
              case "/app/uploads/2022/05/spielesammlung-parcour.png":
                bild = "/parcour";
                break;
              case "/app/uploads/2022/05/spielesammlung-reaktionsspiele.png":
                bild = "/reaktionsspiele";
                break;
              case "/app/uploads/2022/05/spielesammlung-stafette.png":
                bild = "/stafette";
                break;
              case "/app/uploads/2022/05/spielesammlung-torschuss.png":
                bild = "/torschuss";
                break;
              case "/app/uploads/2022/05/spielesammlung-velokennenlernen.png":
                bild = "/velokennenlernen";
                break;
              case "/app/uploads/2022/05/spielesammlung-wurfspiele.png":
                bild = "/wurfspiele";
                break;
            }
            bild = language === "fr" ? bild + "-fr.png" : bild + ".png";
            if (item.parent !== null)
              return item.language.language_code === language ? (
                <Seite
                  kurzinfo={item.kurzinfo}
                  beschreibung={item.beschreibung}
                  tipp={item.tipp}
                  bild={bild}
                  variation={item.variation}
                  language={item.language.language_code}
                  zeit={item.zeit[0]
                    .replace(" Minuten", "'")
                    .replace(" minutes", "'")}
                  title={item.title}
                  technik={item.technik.map((itm) => (
                    <Text>{itm}</Text>
                  ))}
                  material={item.material.map((itm) => (
                    <Text>{itm}</Text>
                  ))}
                  min={item.min}
                  max={item.max}
                  stufe={item.stufe.map((itm) => (
                    <Text>{itm}</Text>
                  ))}
                  terrain={item.terrain.map((itm) => (
                    <Text>{itm}</Text>
                  ))}
                />
              ) : (
                <></>
              );
          });
          setSeiten(seiten);
        });
    }
  }, []);

  const MyDocument = () => <Document>{seiten}</Document>;
  return (
    <div>
      {status && id && (
        <PDFViewer style={styles.viewer}>
          <Document>
            {seiten}
          </Document>
        </PDFViewer>
      )}
      {!status && (
        <div style={styles.loading}>
          <div style={styles.button}>{"bitte warten..."}</div>
        </div>
      )}
      {!id &&
        status /*
        <PDFViewer style={styles.viewer}>
         <MyDocument />
        </PDFViewer>
        */ && (
          <>
            <PDFDownloadLink
              document={<MyDocument />}
              fileName="spielesammlung.pdf"
              style={styles.link}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <div style={styles.loading}>
                    <div style={styles.button}>{"bitte warten..."}</div>
                  </div>
                ) : (
                  <div style={styles.loading}>
                    <div style={styles.button}>{"Downlaod"}</div>
                  </div>
                )
              }
            </PDFDownloadLink>
          </>
        )}
    </div>
  );
}
export default BasicDocument;
